import React, {useRef, useState} from 'react';

import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const ContactForm = () => {

    const form = useRef();

    const [ result, showresult ] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();

        var res  = await fetch('/api/v2/notify/email/contact/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: form.current['contact-name'].value,
                email: form.current['contact-email'].value,
                phone: form.current['contact-phone'].value,
                message: form.current['contact-message'].value,
            })
        
        });
        
        console.log(res);
            showresult(true);

        setTimeout(() => {
            showresult(false);
        }, 5000);
    }


    return (
        <form ref={form} onSubmit={sendEmail} className="gc-contact-form">
        <div className="form-group">
            <label>Nombre</label>
            <input type="text" className="form-control" name="contact-name" required />
        </div>
        <div className="form-group">
            <label>Correo Electrónico</label>
            <input type="email" className="form-control" name="contact-email" required />
        </div>
        <div className="form-group">
            <label>Teléfono</label>
            <input type="tel" className="form-control" name="contact-phone" required />
        </div>
        <div className="form-group mb--40">
            <label>Mensaje</label>
            <textarea className="form-control" name="contact-message" rows="4"></textarea>
            
        </div>
        <div className="form-group">
            <button type="submit" className="gc-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Solicitar</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default ContactForm;