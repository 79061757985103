import React from "react";
import { Link } from "react-router-dom";
import { slugify } from "../../../utils";

const PropOne = ({ projectStyle, portfolio }) => {
  const isSmallDevice = window.innerWidth < 700;
	const isMediumDevice = window.innerWidth < 992;
	// const isLargeDevice = window.innerWidth < 1200;
	console.log(window.innerWidth);

	// if profolio.image is dont have extension. is a related name to image
	// in /images/modules/ folder that have 3 subfolders: 0.5x, 1x, 2x
	// then we need to add the extension and the path to the image and webp extension
	console.log(portfolio.image);
	 if (portfolio.image.indexOf('.') === -1) {
		portfolio.image = `/images/modules/2x/${portfolio.image}.webp`;
		if(isMediumDevice) {
			portfolio.image = `/images/modules/1x/${portfolio.image}.webp`;
		}
		if(isSmallDevice) {
			portfolio.image = `/images/modules/2x/${portfolio.image}/webp`;
		}
	}
	console.log(portfolio.image);
  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail " style={{
		  backgroundImage: `url(${process.env.PUBLIC_URL + portfolio.image})`,
		  backgroundSize: 'cover',
		  backgroundPosition: 'center',
		  backgroundRepeat: 'no-repeat',
		  height: '300px'
		}}>
			<div className="overlay" style={{
			  backgroundColor: 'rgba(0,0,0,0.5)',
			  height: '100%'
			
			}} />

          <Link
            to={
              process.env.PUBLIC_URL +
              `/project-details/${slugify(portfolio.title)}`
            }
          >
            {/* <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" /> */}
          </Link>
		  
        </div>
        <div className="content">
          {/* <span className="subtitle">
            {portfolio.category.map((cat, i) => (
              <span key={i}>{cat}</span>
            ))}
          </span> */}
          <h3 className="title" style={{
			  color: 'white'
		  }}>
            <Link
              to={
                process.env.PUBLIC_URL +
                `/project-details/${slugify(portfolio.title)}`
              }
            >
              {portfolio.title.split(":")[0]}
            </Link>
          </h3>
        </div>
      </div>
    </>
  );
};

export default PropOne;
