import React from "react";
import { Link } from "react-router-dom";

const BannerFour = () => {
  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth < 1024;

  var bannerImage = "/images/brand/cg_900.png";
  if (isMobile) {
    bannerImage = "/images/brand/cg_300.png";
  } else if (isTablet) {
    bannerImage = "/images/brand/cg_600.png";
  }
  return (
    <div className="banner banner-style-4">
      <div className="container">
        <div className="banner-content">
          <h1 className="title">Gestión Ciudad.</h1>
          <p>La gestión municipal que te da tranquilidad y seguridad..</p>
          <div>
            <Link
              to={process.env.PUBLIC_URL + "/modules"}
              className="gc-btn btn-fill-primary btn-large"
            >
              Módulos
            </Link>
          </div>
        </div>
        <div className="banner-thumbnail">
          <div
            className={
              isMobile
                ? "small-thumb"
                : isTablet
                ? "medium-thumb"
                : "large-thumb"
            }
          >
            {/* based on device us cg_900.png, cg_600.png or cg_300.png */}

            <img src={process.env.PUBLIC_URL + bannerImage} alt="Shape" />
          </div>
        </div>

        {/* <div className="banner-thumbnail">
                    <div className="large-thumb">
                        <Tilty perspective={2000}>
                            <img src={process.env.PUBLIC_URL + "/images/ciudad_1.jpg"} alt="Shape" />
                        </Tilty>
                    </div>
                </div> */}
        <div className="banner-social">
          <div className="border-line" />
          <ul className="list-unstyled social-icon">
            {/* <li><a href="https://facebook.com/"><FaFacebookF /> Facebook</a></li>
                        <li><a href="https://twitter.com/"><FaTwitter /> twitter</a></li>
                        <li><a href="https://www.linkedin.com/"><FaLinkedinIn /> Linkedin</a></li> */}
          </ul>
        </div>
      </div>
      <ul className="list-unstyled shape-group-19">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-7.png"}
            alt="Bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default BannerFour;
