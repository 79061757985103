import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectPropTwo from "./itemProp/ProjectPropTwo";
import ProjectData from "../../data/project/ProjectData1.json";

import { Link } from "react-router-dom";

const portfolioData = ProjectData;

const ProjectFour = () => {
  return (
    <div className="section section-padding-equal bg-color-dark">
      <div className="container">
        <SectionTitle
           subtitle="El único sistema de gestión municipal que necesitas"
          title="¿Por qué elegirnos?"
          description={`
            Somos el mejor sistema de gestión municipal del mercado,
            con más de 30 módulos que se adaptan a las necesidades de tu municipio.
            con la mejor relación calidad-precio del mercado y desarrollado durante más de 10 años
            con la experiencia de más de 100 municipios.
            <br /> <br />
                
            Es intuitivo y fácil de usar y totalmente adaptado a la ley, 
                que te permite ahorrar tiempo, dinero y mejorar tu gestión municipal. 
                <br /> <br />
                Se actualiza constantemente para adaptarse a las necesidades de los usuarios 
                y a los cambios legislativos. 
                    <br /> <br /> 
                    Contamos con un equipo de profesionales que te ayudarán a resolver cualquier
                     duda que tengas 24/7.
                `}
          textAlignment="heading-light-left mb--90"
          textColor=""
        />
        <div className="project-add-banner">
          <div className="content">
            <span className="subtitle"></span>
            <h3 className="title">
                <span>¿Quieres saber más?</span>
                <span>¡Contáctanos!</span>
            </h3>
            <div className="brand-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo-light.svg"}
                alt="Gestión Ciudad"
              />
            </div>
          </div>
          <div className="thumbnail">
            <img
              src={process.env.PUBLIC_URL + "/images/modules/1x/Recurso81.webp"}
              alt="Mockup"
            />
          </div>
        </div>

        <div className="row row-45">
          {portfolioData.slice(0, 4).map((data) => (
            <div className="col-md-6" key={data.id}>
              <ProjectPropTwo projectStyle="project-style-2" portfolio={data} />
            </div>
          ))}
        </div>
        <div className="more-project-btn">
          <Link
            to={process.env.PUBLIC_URL + "/modules"}
            className="gc-btn btn-fill-white"
          >
            Descubre todos nuestros módulos
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectFour;
