import React, {useState, useEffect} from 'react';
import ProjectPropOne from './itemProp/ProjectPropOne';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectData from "../../data/project/ProjectData1.json";
import { useParams } from 'react-router-dom';




const filters = [
	{
		id: 1,
		label: "Todos",
    },
    {
		id: 2,
		label: "Impuestos",
    },
    {
		id: 3,
		label: "Gestión",
    },
	{
		id: 4,
		label: "Administración",
	}
];


const AllData = ProjectData;

// has to be a set of unique values otherwise it will show duplicate
var filtersFromData = AllData.map((data) => data.category);
var merged = [].concat.apply([], filtersFromData);
var unique = merged.filter((v, i, a) => a.indexOf(v) === i);
var filtersMap = unique.map((data, i) => {
	return {
		id: i + 1,
		label: data,
	};
});



const ProjectOne = ({parentClass, colSize, itemShow, columnGap}) => {
	const params = useParams();
	const category = params.category;

	console.log(category);
	const [getAllItems] = useState(AllData);
	const [visiableProject] = useState(itemShow ? itemShow : 40);
    const [activeFilter, setActiveFilter] = useState(category ? category :"");
	const [visibleItems, setVisibleItems] = useState([]);
	console.log(activeFilter);
	

	useEffect(() => {
        setActiveFilter(filters[0].label);
		setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
    }, []);

	const handleChange = (e) => {
        e.preventDefault();
		let target = e.target.textContent;

        setActiveFilter(target);

        let tempData = [];

        if (target === filters[0].label) {
			tempData = getAllItems.filter((data) => data.id <= visiableProject);
        } else {
			for (let i = 0; i < getAllItems.length; i++) {
				const element = getAllItems[i];
				let categories = element['category'];
				
				if (categories.includes(target)) {
					tempData.push(element)
				} 
			}
        }
        setVisibleItems(tempData);
    };


    return (
		<>
			<div className={`section section-padding-2 ${parentClass ? parentClass : ""}`}>
                <div className="container">
                <SectionTitle 
                        subtitle="Descubre cómo nuestros módulos te permiten gestionar tu ciudad de forma integral, eficiente y segura. "
                        title="Módulos"
                        textAlignment="heading-left mb--40"
                        textColor=""
                    />
                    <div className="isotope-button isotope-project-btn">

						{filtersMap.map((filter) => (
							<button onClick={handleChange} className={
								filter.label === activeFilter
								? "is-checked"
								: " "
							} 
							key={filter.id}>{filter.label}</button>
						))}

					</div>
					<div className={`row ${columnGap ? columnGap : "row-35"}`}>
						{visibleItems.map((data) => (
							<div className={colSize ? colSize : "col-md-6"} key={data.id}>
								<ProjectPropOne projectStyle="" portfolio={data}/>
							</div>
						))}
					</div>

                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>
            </div>
		</>
    )
}

export default ProjectOne;